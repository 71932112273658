<template>
  <span class="text-xxs font-semibold uppercase" :style="style">
    {{ status.stageName }}
  </span>
</template>
<script setup lang="ts">
import type { PropType } from "vue";
import { computed } from "vue";
import { COLORS, STAGE_TYPE_COLOR_CODES } from "@/helpers/constants";
import type { StageCategoryType } from "@/models/workflows";

interface StatusLable {
  stageName: string;
  stageCategory: StageCategoryType;
}
const props = defineProps({
  status: {
    type: Object as PropType<StatusLable>,
    default: () => ({
      stageName: "",
      stageCategory: "Collect Data"
    })
  }
});

const style = computed(() => {
  const colorIndex = STAGE_TYPE_COLOR_CODES[props.status.stageCategory];
  return { color: `rgba(${COLORS[colorIndex]})` };
});
</script>
